<template>
    <v-dialog :value="value" persistent width="500px">
    <v-card color='#fafafa' width='800px'>
      <v-toolbar flat dark class="main">
        <v-toolbar-title>
        FTP Upload
        </v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 px-4" fluid>
      <v-col>
      <v-menu
        v-model="run_dt_picker"
        transition="scale-transition"
        offset-y
        nudge-top="25"
        max-width="290px"
        min-width="290px">
        <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="params.run_date"
                    label="Run Date"
                    ref="run_date"
                    type="date"
                    prepend-inner-icon="mdi-calendar"
                    dense
                    background-color="#fff"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :rules="[isValidRunDate]">
                </v-text-field>
        </template>
            <v-date-picker 
                  v-model="params.run_date"
                  :max="today"
                  no-title>
            </v-date-picker>
        </v-menu>
        </v-col>
        <v-card-text class="mt-n8">
          <span><strong>File Select:</strong></span>
          <v-row class="mt-1">
          <v-checkbox dense class='ml-3' v-model='params.acct' label='Accounting Files'></v-checkbox>
          <v-checkbox dense class='ml-4' v-model='params.stmt' label='Statement Files'></v-checkbox>
          <v-checkbox dense class='ml-3' v-model='params.billing_details' label='Billing Detail Files'></v-checkbox>
          </v-row>
        </v-card-text>
        <v-card-text class="mt-n3">
            <span><strong>Rerun:</strong></span>
            <v-checkbox  dense v-model='rerun' label='Include files that have previously been pushed to the FTP server'>
            </v-checkbox>
        </v-card-text>
      </v-container>
      <v-footer color=#fafafa>
        <v-spacer/>
        <v-btn color="gray" class='mb-3 mr-5' dense medium
                @click="$emit('closeProcessModal')"
                >
                Cancel
              </v-btn>
              <v-btn color="success" class='mb-3 mr-5' dense medium :disabled='loading || !valid || (!params.acct && !params.stmt && !params.billing_details)' :loading="loading"
                @click="triggerFtpPush()" 
                >
                Start
              </v-btn>
        </v-footer>
      </v-card> 
    </v-dialog>
</template>

<script>
import { displayAlert } from '@/mixins/displayAlert'
import { utils } from '@/mixins/utils'
export default {
  name: 'FTPPush',

  props: ['value', 'method'],
  mixins: [displayAlert, utils],

  data () {
    return {
      today: new Date().toISOString().slice(0, 10),
      run_dt_picker: false,
      loading: false,
      valid: true,
      rerun: false,
      params: {
        run_date: new Date().toISOString().slice(0, 10),
        acct: true,
        stmt: true,
        billing_details: true,
        net_new: true,
        sftp: false
      }
    }
  },

  methods: {
    async triggerFtpPush() {
      this.loading = true
      try {
        this.params.net_new = !this.rerun
        this.params.sftp = this.method.toLowerCase().includes('sftp')
        const res = await this.$BillingBatch.ftpPushTrigger(this.params)
        if (res?.status === 200) {
            let files = []
            if (this.params.acct) {
                    files.push("Accounting")
                }
            if (this.params.stmt) {
                    files.push("Statement")
                }
            if (this.params.billing_details) {
                    files.push("Billing Detail")
                }
            let file_string = files.join(" and ")
            let message = "Sending " + file_string + " Files"
            this.emitAlert(true, 'success', message)
            }
            this.$emit('closeProcessModal')
        }
      catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
      },

    isValidRunDate (value) {
      if (value) {
        if (!this.isValidYear(value)) {
          this.run_dt_picker = false
          this.valid = false
          return 'Invalid year value'
        }
        this.valid = true
        return true
      }
      this.valid = false
      return 'Start Date is required'
  },
    isValidYear (value) {
      if (value) {
        let [year] = value.split('-')
        year = Number(year)
        const currentYear = new Date().getFullYear()
        if (year == currentYear) {
          return true
        }
        else {
          return false
        }
      }
      return false
    },
}
}
</script>
<style>
.v-text-field__slot input::-webkit-calendar-picker-indicator {
  display: none;
}

.v-text-field__slot input[type="date"] {
  cursor: text;
}

.v-text-field__slot input[type="date"]::-webkit-input-placeholder {
  visibility: hidden;
}
</style>
